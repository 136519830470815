import { apiSlice } from "../../app/api/apiSlice";

export const sharedAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPppPackages: builder.query({
            query: ()=> `reseller/ppp/packages`,
        }),
        getPackageCategories: builder.query({
            query: ()=> `reseller/plangroup`
        }),
        getHotspots: builder.mutation({
            query: (reseller)=> ({
                url: 'reseller/hotspot/list',
                method: 'POST',
                body: {...reseller}
            })
        }),

    })
});

export const { useGetHotspotsMutation, useGetPackageCategoriesQuery, useGetPppPackagesQuery } =  sharedAPiSlice;