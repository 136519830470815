import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetPppTranferReportMutation } from "./transferReportApiSlice"
import { useSelector } from "react-redux"
import { Col, Row, Card } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { formatTimeInZ } from "../../../helpers"

const TransferReport = () => {
  const [getTranferReport, { isLoading }] = useGetPppTranferReportMutation()
  const { user } = useSelector((state) => state.auth)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  //
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const transfers = await getTranferReport({
          resellerId: user.companyId,
          pageSize: 100,
          pageNumber: currentPage
        }).unwrap()


        setData(transfers);
        setCurrentPage(transfers.currentPage);
      } catch (error) {
        setError(error?.data)
      }
    }
    fetchReport()
  }, [currentPage, user.companyId, getTranferReport])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const columns = [
    { name: "id", description: "#" },
    { name: "accountnumber", description: "Account Number" },
    { name: "channel", description: "Payment Channel" },
    { name: "transfer_datetime", description: "Transfer Date Time" },
    { name: "phone_number", description: "Payee Phone" },
    { name: "transfer_reference", description: "Transfer Reference" },
    { name: "status", description: "Payment Status" },
    { name: "plan_name", description: "Package Name" },
    { name: "amount", description: "Amount" },
    { name: "transfer_type", description: "Transfer Type" },
    { name: "request_description", description: "Request Description" },
  ];

  const formatedData = (data?.results || []).map(tx => {
    return {
        ...tx, 
        amount: `KES ${Number(tx.amount).toFixed(2)}`,
        transfer_datetime: formatTimeInZ(tx.transfer_datetime)
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading}
          error={error}
          onPageChnage = {handlePageChange}
          pagination={{
            currentPage: data?.currentPage,
            pageSize: data?.pageSize,
            totalItems: data?.totalItems,
            next: data?.next,
            previous: data?.previous,
            pagesTotal: data?.pagesTotal
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"PPP Transfer Report"}>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default TransferReport
