import toast from 'react-hot-toast';

export const readDataFromLocalStorage = (key) => {
    try {
        return JSON.parse(localStorage.getItem(key))
    } catch (error) {
        return {}
    }
}



export const formatime =  (duration, unit) => {
    if(duration>1) {
        return  `${duration} ${unit}s`
    }
    return `${duration} ${unit}`
}

export const formatTimeInZ = (dateTime)=> {
    // const dateTimeArr = dateTime.split('T');
    // const timeArray = dateTimeArr[1].split('.');
    // const time = timeArray[0]
    const date = new Date(dateTime).toLocaleString() 
    return date;

}

export const formatMoney = (amount)=> {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES'
    }).format(amount)
}


export const notify = (message, isError) => {
    if(isError) {
      toast.error(message)
      return;
    }
    toast.success(message)
    return
  };