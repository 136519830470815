import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import CustomDialog from "../../../../components/CustomDialog"
import ChangePlanForm from "./ChangePlanForm"
import { useEditPPPUserSubscriptionMutation } from "../pppUsersApiSlice"
import { notify } from "../../../../helpers"

const ChangePlan = ({ show, handleShow, packages, handleRefetch, currentPlanId, accountNumber }) => {
  const [editPPPUserSubscription, { isLoading }] =
    useEditPPPUserSubscriptionMutation()
  const [createError, setCreateError] = useState(null)

  const [initialValues, setInitialValue] = useState({
    planId: currentPlanId || '',
  })

  useEffect(()=> {
    if(show && currentPlanId) {
      setInitialValue({planId: currentPlanId})
    }

  }, [currentPlanId, show])


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
    enableReinitialze: true,
  })

  const saveAndCLose = async (values, reset) => {
    const plan = {
       
        planId: Number(values.planId),
        accountNumber: accountNumber
      }
      // do logic to save data
  
      try {
        await editPPPUserSubscription(plan).unwrap()
        reset()
        handleShow(false)
        setCreateError(null)
        handleRefetch(true)
        notify('Plan Updated Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. please try again', true)
      }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    formik.resetForm()
  }

  return (
    <Form method="post" onSubmit={formik.handleSubmit}>
      <CustomDialog
        show={show}
        title={"Change Plan"}
        onCLose={handleCloseDialog}
        onSave={formik.handleSubmit}
        isLoading={isLoading}
        size={'md'}
      >
        <ChangePlanForm
          formik={formik}
          error={createError}
          packages={packages}
          defaultPlan={currentPlanId}
        />
      </CustomDialog>
    </Form>
  )
}

// validation
const validationSchema = Yup.object({
  planId: Yup.number().required("package is required"),
})

export default ChangePlan
