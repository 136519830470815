import React from "react"
import { Routes, Route } from "react-router-dom"
import Main from "./layouts/Main"
import NotFound from "./pages/NotFound"

import publicRoutes from "./routes/PublicRoutes"
import protectedRoutes from "./routes/ProtectedRoutes"

// import css
import "./assets/css/remixicon.css"

// import scss
import "./scss/style.scss"
import './index.css'
import Protected from "./components/Protected"

import Layout from "./components/Layout"
import RequireAuth from "./components/RequireAuth"
import PersistLogin from "./components/PersistLogin"

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode")
  let HTMLTag = document.querySelector("html")

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode)
  }
})

export default function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public route */}
          {publicRoutes.map((route, index) => {
            return (
              <Route path={route.path} element={route.element} key={index} />
            )
          })}

          {/* protected */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Main />}>
                {protectedRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={<Protected>{route.element}</Protected>}
                      key={index}
                    />
                  )
                })}
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </React.Fragment>
  )
}
