import React, { useState } from "react"
import { notify } from "../../../../helpers"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import { useCreatePPPUsersMutation } from "../pppUsersApiSlice"
import CustomDialog from "../../../../components/CustomDialog"
import UserForm from "./UserForm"

const CreateCustomer = ({ show, handleShow, packages, handleRefetch }) => {
  const [createPPPUsers, { isLoading }] = useCreatePPPUsersMutation()
  const [createError, setCreateError] = useState(null)
  const [initialValues] = useState({
    username: "",
    password: "",
    cpassword: "",
    email: "",
    phone: "",
    type: "",
    firstname: "",
    lastname: "",
    planId: "",
  })

  const createFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

 
  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const customer = {
      username: values.email,
      password: values.password,
      email: values.email,
      phone: values.phone,
      type: values.type,
      firstname: values.firstname,
      lastname: values.lastname,
      planId: Number(values.planId),
    }
    // do logic to save data

    try {
      await createPPPUsers(customer).unwrap()
      resetForm()
      handleShow(false)
      setCreateError(null)
      handleRefetch(true)
      notify('User added Successfuly', false)
    
    } catch (error) {
      setCreateError(error.data)
      notify('Failed. please try again', true)
     
    }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    createFormik.resetForm()
  }

  return (
    <>
      <Form method="post" onSubmit={createFormik.handleSubmit} autoComplete="off">
        <CustomDialog
          show={show}
          title={"Add PPP User"}
          onCLose={handleCloseDialog}
          onSave={createFormik.handleSubmit}
          isLoading={isLoading}
          size={"lg"}
        >
          <UserForm
            formik={createFormik}
            error={createError}
            packages={packages}
          />
        </CustomDialog>
      </Form>
    </>
  )
}

// validation
const validationSchema = Yup.object({
  password: Yup.string().required("password is required"),
  cpassword: Yup.string()
    .required("password is required")
    .oneOf([Yup.ref("password"), null], "Passwords does not match"),
  email: Yup.string()
    .required("email is required")
    .email("Invalid email format"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "invalid phone")
    .required("This field is required")
    .length(10, "invalid phone  format"),
  type: Yup.string().required("User type must be specified"),
  firstname: Yup.string().required("firstname is required"),
  lastname: Yup.string().required("firstname is required"),
  planId: Yup.number().required("package is required"),
})

export default CreateCustomer
