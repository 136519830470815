import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import { useGetRoutersQuery, useCreateRouterMutation, useUpdateRouterMutation } from "./routerApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import RouterForm from "./RouterForm"
import { notify } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "id", description: "ID" },
  { name: "nasname", description: "Router IP Address" },
  { name: "type", description: "Router Type" },
  { name: "ports", description: "Router Radius Port" },
  { name: "server", description: "Radius Server" },
  { name: "description", description: "Description" },
  { name: "location", description: "Location" },
  { name: "landmark", description: "Landmark" },
]

const Routers = () => {
  // state
  const {
    data,
    error: fetchRoutersErrors,
    isLoading: isFetching,
    refetch
  } = useGetRoutersQuery()

  const [createRouter, { isLoading: isCreating }] = useCreateRouterMutation()
  const [updateRouter, { isLoading: isEditing }] = useUpdateRouterMutation()

  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Router")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    ipAddress: "",
    description: "",
    location: "",
    landmark: "",
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  // handlers
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Add Router")
    setFormErrors(null)
  }

  const handleShowModalToEdit = () => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Router")
    setFormErrors(null)
    formik.setValues(initialValues)

  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addRouter = async (values, resetForm)=> {
    try {
      await createRouter(values).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router added Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const editRouter = async (values, resetForm)=> {
    try {
      await updateRouter({...values, id: selected.id}).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router updated Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if(action === 'add') {
      await addRouter(values, resetForm);
    }else {
      await editRouter(values, resetForm);
    }
  }

  const onSelect = (isSelected, row, index)=> {
    setSelected(null)
    if(isSelected) {   // insert
      const rawData = data.find(d=> d.id === index)
      setSelected({
        ...rawData
      })
      // store the values for editing
      setInitialValues(prevState => ({
        ...prevState,
        ipAddress:  rawData?.nasname,
        description: rawData?.description,
        location: rawData?.location,
        landmark: rawData?.landmark
      }))
    }
  }

  useEffect(()=> {
  }, [selected])

  // on state changes
  useEffect(() => {
    if(isCreating || isEditing) {
      setInprogress(true)
    }else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])

  // table format
  let count = 0
  const routerData = (data || []).map((router) => {
    count++
    return {
      ...router,
      count,
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={routerData}
          isSelectable={true}
          onSelect={onSelect}
          selected={selected}
          isLoading={isFetching}
          error={fetchRoutersErrors}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Routers"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button disabled={!!Boolean(selected)} onClick={handleShowModalToAdd} variant="primary">
                  Add{" "}
                </Button>
              </div>
              <div>
                <Button disabled={!!!Boolean(selected)} onClick={handleShowModalToEdit} variant="success">
                  Edit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <RouterForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
      />
    </>
  )
}

export default Routers

//validate ip ip
function ipv4(message = "Invalid IP address") {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true,
  }).test("ip", message, (value) => {
    return value === undefined || value.trim() === ""
      ? true
      : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined
  })
}

Yup.addMethod(Yup.string, "ipv4", ipv4)

// validation
const validationSchema = Yup.object({
  ipAddress: Yup.string().ipv4("please enter valid ipv4 address"),
  description: Yup.string().required("description is required"),
  location: Yup.string().required("location is required"),
  landmark: Yup.string().required("landmark must be specified"),
})
