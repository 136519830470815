import { apiSlice } from "../../../../app/api/apiSlice";

export const activeUsersAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getActiveUsers: builder.query({
            query: (params)=> {
                let url = 'reseller/customer/list'
                if(params.pageSize) {
                    url = url + `?pageSize=${params.pageSize}`
                }
                if(params.pageNumber) {
                    url = url + `&pageNumber=${params.pageNumber}`
                }
                if(params.phone) {
                    url = url + `&phone=${params.phone}`
                }
                return {
                    url
                }
            }
        }),
        updateAccountStatus: builder.mutation({
            query: (params) => ({
              url: "reseller/account/hotspot/update-status",
              method: "POST",
              body: { ...params },
            }),
          }),

    })
});

export const { useGetActiveUsersQuery, useUpdateAccountStatusMutation } =  activeUsersAPiSlice;