import { apiSlice } from "../../app/api/apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
    }),
    getAccessToken: builder.mutation({
      query: (token) => ({
        url: "/auth/refresh",
        method: "POST",
        body: {
          refreshToken: token,
        },
      }),
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation, useGetAccessTokenMutation } = authApiSlice
