import React, { useState } from "react"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import CustomDialog from "../../../../components/CustomDialog"
import DiscountForm from "./DiscountForm"
import { useGiveDiscountMutation } from "../pppUsersApiSlice"
import { notify } from "../../../../helpers"

const GiveDiscount = ({ show, handleShow, handleRefetch, accountNumber }) => {
  const [giveDiscount, { isLoading }] =
  useGiveDiscountMutation()
  const [createError, setCreateError] = useState(null)

  const [initialValues] = useState({
    percentage: '',
  })




  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
    enableReinitialze: true,
  })

  const saveAndCLose = async (values, reset) => {
    const discount = {
        percentage: Number(values.percentage),
        accountNumber: accountNumber
      }
      // do logic to save data
  
      try {
        await giveDiscount(discount).unwrap()
        reset()
        handleShow(false)
        setCreateError(null)
        handleRefetch(true)
        notify('Wallet Updated Sucessfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. please try again', true)
      }
  }

  const handleCloseDialog = () => {
    handleShow(false)
    formik.resetForm()
  }

  return (
    <Form method="post" onSubmit={formik.handleSubmit}>
      <CustomDialog
        show={show}
        title={"Give Discount"}
        onCLose={handleCloseDialog}
        onSave={formik.handleSubmit}
        isLoading={isLoading}
        size={'md'}
      >
        <DiscountForm
          formik={formik}
          error={createError}
        />
      </CustomDialog>
    </Form>
  )
}

// validation
const validationSchema = Yup.object({
  percentage: Yup.number().required("package is required").min(1).max(100),
})

export default GiveDiscount
