import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"


const UserForm = ({ formik, error, packages }) => {
 
  const plans = (packages || []).map((plan) => {
    return (
      <option key={plan.id} value={plan.id}>
        {plan.plan_name.toUpperCase()}
      </option>
    )
  })

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {error && error?.message && (
              <Alert variant="danger">{error?.message}</Alert>
            )}
          </Col>
        </Row>
        
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                type="text"
                placeholder="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="firstname"
              />
              {formik.errors.firstname && formik.touched.firstname && (
                <Form.Text className="text-danger">
                  {formik.errors.firstname}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                type="text"
                placeholder="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="lastname"
              />
              {formik.errors.lastname && formik.touched.lastname && (
                <Form.Text className="text-danger">
                  {formik.errors.lastname}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Customer Type</Form.Label>
              <Form.Select
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="type"
              >
                <option>Open this select menu</option>
                <option  key='individual' value='individual'>Individual</option>
                <option  key='corporate' value='corporate'>Corporate</option>
              </Form.Select>
              {formik.errors.type && formik.touched.type  && (
                <Form.Text className="text-danger">
                  {formik.errors.type}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
              />
              {formik.errors.email && formik.touched.email && (
                <Form.Text className="text-danger">
                  {formik.errors.email}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="0xxxxxxxx"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone"
              />
              {formik.errors.phone && formik.touched.phone && (
                <Form.Text className="text-danger">
                  {formik.errors.phone}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Package</Form.Label>
              <Form.Select
                value={formik.values.planId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="planId"
              >
                <option>Open this select menu</option>
                {plans}
              </Form.Select>
              {formik.errors.planId && formik.touched.planId  && (
                <Form.Text className="text-danger">
                  {formik.errors.planId}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="password"
              />
              {formik.errors.password && formik.touched.password && (
                <Form.Text className="text-danger">
                  {formik.errors.password}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={formik.values.cpassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="cpassword"
              />
              {formik.errors.cpassword && formik.touched.cpassword && (
                <Form.Text className="text-danger">
                  {formik.errors.cpassword}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default UserForm
