import React from "react"
import { Form, Row, Col } from "react-bootstrap"

const ResellerForm = ({ data }) => {
  return (
    <>
      <Form>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control value={data?.name} disabled={true} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Company Size</Form.Label>
              <Form.Control
                disabled={true}
                value={`${data?.campanysizemin || 0} - ${
                  data?.campanysizemax || 1
                }`}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Customer Size</Form.Label>
              <Form.Control
                disabled={true}
                value={`${data?.customerSizeMin || 0} - ${
                  data?.customerSizeMax || 1
                }`}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Consumer Secret</Form.Label>
              <Form.Control disabled={true} value={data?.consumer_secret} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Consumer Key</Form.Label>
              <Form.Control disabled={true} value={data?.consumer_key} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Short Code</Form.Label>
              <Form.Control disabled={true} value={data?.shortcode} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Physical Andress</Form.Label>
              <Form.Control disabled={true} value={data?.region} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Active Phone</Form.Label>
              <Form.Control disabled={true} value={data?.phone} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Active Email </Form.Label>
              <Form.Control disabled={true} value={data?.email} />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ResellerForm
