import React from "react";
import { Doughnut } from "react-chartjs-2"
import "chart.js/auto"
import { Card } from "react-bootstrap";
import { generateRandomColors } from "../../../helpers/randomColors";
import { formatime } from "../../../helpers";

const MostUsedPackage = ({data}) => {
  const labels = (data|| []).map(d => {
    const duration  = formatime(d.duration, d.timeunit);
    return `${duration} @ KES ${d.price}`
  })
  const cxData = (data|| []).map(d => {
    return d.total
  })
  const colors = generateRandomColors(labels.length)

  const dataPie = {
    labels: labels,
    datasets: [
      {
        data: cxData,
        backgroundColor: colors,
      },
    ],
  }

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  }
  return  (
    <Card className="ml-5">
      <Card.Header>
        <Card.Title as="h6">Most used Plan</Card.Title>
      </Card.Header>
      <Card.Body>
        <Doughnut data={dataPie} options={optionPie} className="ht-350"  />
      </Card.Body>
    </Card>
  )
}

export default MostUsedPackage
