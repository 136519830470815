import React, { useEffect } from "react"
import Prism from "prismjs"
import ReactApexChart from "react-apexcharts"
import { Card } from "react-bootstrap"
import { generateRandomColors } from "../../../helpers/randomColors"
import { formatMoney } from "../../../helpers"

const IncomePerMonth = ({ data=[] }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  // Column Chart
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const dataInArrays = (data && Object.values(data)) || []
  const keys = (dataInArrays && Object.keys(dataInArrays[0] || {})) || []

  const seriesData  = keys.map(key => {
    const values = dataInArrays.map(obj => obj[key])
    return {
      name: key,
      data: values
    }
  })
  const colors = generateRandomColors(keys.length)

  const seriesColumn = seriesData

  const optionColumn = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: months
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatMoney(val)
        },
      },
    },
  }

  return (
    <>
      <Card className="">
        <Card.Header><h6>Month on Month Income per Plan in KES</h6></Card.Header>
        <Card.Body>
          <ReactApexChart
            series={seriesColumn}
            options={optionColumn}
            type="bar"
            height={350}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default IncomePerMonth
