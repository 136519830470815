import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"


const ChangePlanForm = ({ formik, error, packages, defaultPlan }) => {
  const plans = (packages || []).map((plan) => {
    return (
      <option key={plan.id} value={plan.id}>
        {plan.plan_name.toUpperCase()}
      </option>
    )
  })

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {error && error?.message && (
              <Alert variant="danger">{error?.message}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Package</Form.Label>
              <Form.Select
                value={ defaultPlan ||  formik.values.planId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="planId"
              >
                <option>Open this select menu</option>
                {plans}
              </Form.Select>
              {formik.errors.planId && formik.touched.planId  && (
                <Form.Text className="text-danger">
                  {formik.errors.planId}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ChangePlanForm
