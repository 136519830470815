import { apiSlice } from "../../../app/api/apiSlice";

export const transferReportAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPppTranferReport: builder.mutation({
            query: (reseller)=> ({
                url: 'reseller/ppp/transfer/report',
                method: 'POST',
                body: {...reseller}
            })
        }),

    })
});

export const { useGetPppTranferReportMutation } =  transferReportAPiSlice;