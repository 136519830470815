import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Form, Card, Col, Row, Button } from "react-bootstrap"
import {
  useCreatePppPackageMutation,
  useEditPppPackageMutation,
  useGetPppPackagesQuery
} from "./packageApiSlice"
import MainGrid from "../../../../components/MainGrid"
import Dashboard from "../../../../components/Dashboard"
import { formatime, notify } from "../../../../helpers"
import CustomDialog from "../../../../components/CustomDialog"
import PackageForm from "./PackageForm"

const columns = [
  { name: "itemId", description: "#" },
  { name: "id", description: "Plan Id" },
  { name: "plan_name", description: "Package Name", sort: true },
  { name: "upload_speed", description: "Upload Speed" },
  { name: "download_speed", description: "Download Speed" },
  { name: "bundle", description: "Bunde Size" },
  { name: "price", description: "Price" },
  { name: "duration", description: "Duration" },
  { name: "pool_name", description: "Pool Name" },
  { name: "fup_enabled", description: "FUP" },
]

export default function PppPackages() {
const {data, error: fetchErrors, isLoading, refetch} = useGetPppPackagesQuery()
  const [createPackage, { isLoading: isCreating }] = useCreatePppPackageMutation()
  const [editPackage, { isLoading: isEditing }] = useEditPppPackageMutation()

  const [selected, setSelected] = useState(null)
  const [createError, setCreateError] = useState(null)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Create PPP Plan")
  const [initialValues, setInitialValues] = useState({
    bundle:  "",
    price: "",
    duration: "",
    timeunit: "",
    name: "",
    downloadMaxSpeed: "",
    uploadMaxSpeed: ""
  })



  // modal logic
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Create PPP Plan")
  }

  const handleShowModalToEdit = () => {
    setShow(true)
    setAction("edit")
    setTitle("Edit PPP Plan")
    formik.setValues(initialValues)
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const plan = {
      bundle: Number(values.bundle),
      price: Number(values.price),
      duration: Number(values.duration),
      timeunit: values.timeunit,
      name: values.name,
      uploadMaxSpeed: Number(values.uploadMaxSpeed),
      downloadMaxSpeed: Number(values.downloadMaxSpeed),
    }
    // do logic to save data
    if (action === "add") {
      try {
        await createPackage(plan).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        notify('PPP package added Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    } else {
      try {
        const editedPackage = await editPackage({packageId:selected.id, plan}).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        setInitialValues(editedPackage)
        refetch()
        notify('PPP package edited Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    }
  }

  // on mount, get list of packages
  // useEffect(() => {
  //   if(requireFetch) {
  //     const fetchPackages = async () => {
  //       try {
  //         const packages = await getPackages({
  //           resellerId: user.companyId,
  //         }).unwrap()
  //         setData(packages)
  //         setRequireFetch(false)
  //       } catch (error) {
  //         setError(error?.data)
  //       }
  //     }
  //     fetchPackages()

  //   }
  // }, [requireFetch, getPackages, user.companyId])

  // row select - either delete or edit or view
  const handleSelect = (isSelected, row, index) => {
    setSelected(null)
    if(isSelected) {   // insert
      const rawData = data.find(d=> d.id === index)
      setSelected({
        ...rawData
      })
      console.log(rawData)
      setInitialValues(prevState => ({
        ...prevState,
        bundle:  rawData?.bundle,
        price: rawData?.price,
        duration: rawData?.duration,
        timeunit: rawData?.timeunit,
        name: rawData?.plan_name,
        uploadMaxSpeed: rawData?.upload_speed,
        downloadMaxSpeed: rawData?.download_speed,
      }))
    }
  }

  useEffect(()=> {
  }, [selected])

  // columns
let itemId = 0
  const formatedData = (data || []).map((plan) => {
    itemId ++
    return {
      ...plan,
      upload_speed: `${plan.upload_speed} Mbps`,
      download_speed: `${plan.download_speed} Mbps`,
      price: `${Number(plan.price).toFixed(2)} KES`,
      duration: formatime(plan.duration, plan.timeunit),
      bundle: `${plan.bundle} GB`,
      fup_enabled: plan.fup_enabled ? 'true': 'false',
      itemId: itemId

    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={true}
          onSelect={handleSelect}
          isLoading={isLoading || isEditing}
          error={fetchErrors}
          selected={selected}
        />
      </Card.Body>
    </Card>
  )
  return (
    <React.Fragment>
      <Dashboard title="PPP Packages">
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button disabled={!!Boolean(selected)} onClick={handleShowModalToAdd} variant="primary">
                  Add{" "}
                </Button>
              </div>
              {/* <div>
                <Button disabled variant="secondary">Add & Close</Button>
              </div> */}
              <div>
                <Button disabled={!!!Boolean(selected)} onClick={handleShowModalToEdit} variant="success">
                  Edit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <Form method="post" onSubmit={formik.handleSubmit}>
        <CustomDialog
          show={show}
          title={title}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isCreating}
          size={'lg'}
          
        >
          <PackageForm formik={formik} error={createError } defaultData={selected} />
        </CustomDialog>
      </Form>
    </React.Fragment>
  )
}



// validation
const validationSchema = Yup.object({
  price: Yup.number().required("Price is required"),
  duration: Yup.number().required("duration is required"),
  timeunit: Yup.string().required("time unit must be specified"),
  bundle: Yup.number().required("bundle is required"),
  name: Yup.string().required("Package name is required"),
  uploadMaxSpeed: Yup.number().required("Upload speed is required"),
  downloadMaxSpeed: Yup.number().required("Download speed is required")
})
