const dashboardMenu = [
  // {
  //   "label": "General Dashboard",
  //   "link": "/dashboard/general",
  //   "icon": "ri-dashboard-line"
  // },
  {
    label: "Hotspot Dashboard",
    link: "/dashboard/hotspot/finance",
    icon: "ri-dashboard-2-fill",
  },
  {
    label: "PPP Dashboard",
    link: "/dashboard/ppp/finance",
    icon: "ri-dashboard-3-line",
  },
]

const packages = [
  {
    label: "Packages",
    link: "/packages/list",
    icon: "ri-folder-2-line",
  },
]

const hotspots = [
  {
    label: "All Hotspots",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },

  {
    label: "Active Hotspots",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },
]

const reports = [
  {
    label: "mpesa Payments Report",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },
  {
    label: "Transfer Report",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },
]

const accounts = [
  {
    label: "Reseller Info",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },
]

const resellerMenus = [
  {
    label: "Info",
    link: "reseller/info",
    icon: "ri-account-pin-box-fill",
  },
]

const configuration = [
  {
    label: "Packages",
    icon: "ri-folder-2-line",
    submenu: [
      {
        label: "Hotspot Packages",
        link: "reseller/hotspot/packages/list",
      },
      {
        label: "PPP Packages",
        link: "reseller/ppp/packages/list",
      },
    ],
  },
  {
    label: "Routers",
    link: "reseller/routers/list",
    icon: "ri-router-line",
  },
  {
    label: "Hotspots",
    link: "reseller/hotspots/list",
    icon: "ri-hotspot-line",
  },
]

const userManagementMenus = [
  {
    label: "Hotspot Users",
    link: "reseller/hotspot/customers/list",
    icon: "ri-team-fill",
  },
  {
    label: "PPP Users",
    link: "reseller/ppp/customers/list",
    icon: "ri-admin-line",
  },
]

const reportsMenu = [
  {
    label: "Hotspot Transfer Report",
    link: "reseller/hotspot/transfer/report",
    icon: "ri-file-chart-fill",
  },
  {
    label: "PPP Transfer Report",
    link: "reseller/ppp/transfer/report",
    icon: "ri-folder-chart-2-fill",
  },
]

const billingMenu = [
  {
    label: "Billing",
    link: "reseller/billing",
    icon: "ri-money-dollar-circle-fill",
  },
]

export {
  dashboardMenu,
  packages,
  hotspots,
  reports,
  accounts,
  resellerMenus,
  configuration,
  userManagementMenus,
  reportsMenu,
  billingMenu,
}
