import { apiSlice } from "../../../app/api/apiSlice";

export const dashboardAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPPPDashboard: builder.query({
            query: ()=> ({
                url: 'reseller/ppp/dashboard'
            })
        }),

    })
});

export const { useGetPPPDashboardQuery } =  dashboardAPiSlice;