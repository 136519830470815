import { apiSlice } from "../../../app/api/apiSlice";

export const routerAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRouters: builder.query({
            query: (reseller)=> ({
                url: 'reseller/routers/list',
            })
        }),
        createRouter: builder.mutation({
            query: router => ({
                url: 'reseller/add/router',
                method: 'POST',
                body: {...router}
            })
        }),

        updateRouter: builder.mutation({
            query: router => ({
                url: `reseller/edit/router/${router.id}`,
                method: 'PATCH',
                body: {
                    description: router.description,
                     location: router.location,
                     landmark: router.landmark,
                     ipAddress: router.ipAddress
                }
            })
        }),

    })
});

export const { useGetRoutersQuery, useCreateRouterMutation, useUpdateRouterMutation } =  routerAPiSlice;