import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Row, Table, Button } from "react-bootstrap"
import { useGetPppPackagesQuery } from "../../../../app/api/sharedApiSlice"
import Dashboard from "../../../../components/Dashboard"
import img1 from "../../../../assets/img/img1.jpg"
import ChangePlan from "../ChangePlan"
import GiveDiscount from "../GiveDiscount"
import { useGetPppUserProfileQuery, useDeActivatePPPUserMutation, useActivatePPPUserMutation } from "./../pppUsersApiSlice"
import { formatime, notify } from "../../../../helpers"

export default function Profile() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const { data: packages, error: fetchErrors } = useGetPppPackagesQuery()
  const [deActivatePPPUser] = useDeActivatePPPUserMutation()
  const [activatePPPUser] = useActivatePPPUserMutation()
  const [requireFetch, setRequireFetch] = useState(true)
  const {
    data: userProfile,
    error: profileErrors,
    isLoading,
    refetch,
  } = useGetPppUserProfileQuery(routeParams.accountNumber)
  const [changePlanDialog, handleShowaddChangePlanDialog] = useState(false)
  const [giveDiscountDialog, handleGiveDiscountDialog] = useState(false)
  const {
    accountInfo = {},
    customerInfo = {},
    subscriptionInfo = {},
  } = userProfile || {}

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleShowChangePlanModal = () => {
    handleShowaddChangePlanDialog(true)
  }

  const handleShowDiscountForm = () => {
    handleGiveDiscountDialog(true)
  }

  const handleUserActivate = async()=> {
    try {
      await activatePPPUser(accountInfo?.account_number).unwrap()
      setRequireFetch(true)
      notify('Activated Successfully', false)
    } catch (error) {
      notify('Failed. please try again', true)
    }
  }

  const handleDeactivateUser = async ()=> {
    try {
      await deActivatePPPUser(accountInfo?.account_number).unwrap()
      setRequireFetch(true)
      notify('Deactivated Successfully', false)
    } catch (error) {
      notify('Failed. please try again', true)
    }
  }

  useEffect(() => {
    if (requireFetch) {
      refetch()
    }
    return () => setRequireFetch(false)
  }, [requireFetch, refetch, subscriptionInfo?.planid])

  if (profileErrors || fetchErrors) {
    return <p>Something wrong happen. please call support 0705814794</p>
  }

  if (isLoading) {
    return <p>Loading</p>
  } else {
    return (
      <>
        <Dashboard title={"Customer Profile"}>
          <Row className="mb-5">
            <Col>
              <div className="align-items-space-between">
                <div>
                  <div>
                    <Button variant="danger" onClick={handleGoBack}>
                      Go Back{" "}
                    </Button>
                  </div>
                </div>
                <div className="align-items-right btn-group">
                  <div>
                    <Button variant="primary" onClick={handleShowDiscountForm}>Give Discount </Button>
                  </div>
                  <div>
                    <Button
                      disabled={
                        subscriptionInfo?.subscription_status === "active"
                      }
                      variant="warning"
                      onClick={handleShowChangePlanModal}
                    >
                      Change Plan{" "}
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={
                        subscriptionInfo?.subscription_status === "active"
                        ||
                        Number(subscriptionInfo?.price || 0) > Number(accountInfo?.wallet_balance || 0)
                      }
                      variant="success"
                      onClick={handleUserActivate}
                    >
                      Activate{" "}
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="secondary"
                      disabled={
                        subscriptionInfo?.subscription_status === "inactive"
                      }
                      onClick={handleDeactivateUser}
                    >
                      Deactivate{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-5">
            <Col xs={12} md={4} xl={4}>
              <div className="media-profile mb-5">
                <div className="media-img mb-3 mb-sm-0">
                  <img src={img1} className="img-fluid" alt="..." />
                </div>
                <div className="media-body">
                  <h5 className="media-name">
                    {customerInfo?.firstname} {customerInfo?.lastname}
                  </h5>
                  <p className="d-flex gap-2 ">
                    <i className="ri-map-pin-line"></i> Not set
                  </p>
                  <p className="d-flex gap-2 ">
                    <i className="ri-mail-line"></i> {customerInfo?.email}
                  </p>
                  <p className="d-flex gap-2 ">
                    <i className="ri-phone-fill"></i> {customerInfo?.phone}
                  </p>
                </div>
              </div>
              <Card className="card-post">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>customer Id</th>
                        <th>Account Number</th>
                        <th>Wallet Balance</th>
                        <th>Account Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{accountInfo?.customer_id}</td>
                        <td>{accountInfo?.account_number}</td>
                        <td>
                          KES{" "}
                          {Number(accountInfo?.wallet_balance || 0).toFixed(2)}
                        </td>
                        <td>{accountInfo?.account_status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={8} xl={8}>
              <Card className="card-post mt-4">
                <Card.Header>
                  <Card.Title>PPPoE Credentials</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table striped={false}>
                    <thead>
                      <tr>
                        <th>username</th>
                        <th>password</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{accountInfo?.account_number}</td>
                        <td>
                          <code>{customerInfo?.ppp_password}</code>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              <Card className="card-post mt-4">
                <Card.Header>
                  <Card.Title>Subscription</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table bordered={false} striped={false} borderless>
                    <tbody>
                      <tr>
                        <td>Package</td>
                        <td>{subscriptionInfo?.plan_name?.toUpperCase()}</td>
                      </tr>
                      <tr>
                        <td>Price</td>
                        <td>
                          KES {Number(subscriptionInfo?.price || 0).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>subscription Status</td>
                        <td>{subscriptionInfo?.subscription_status}</td>
                      </tr>
                      <tr>
                        <td>Download Speed</td>
                        <td>{subscriptionInfo?.download_speed} Mbps</td>
                      </tr>
                      <tr>
                        <td>Upload Speed</td>
                        <td>{subscriptionInfo?.upload_speed} Mbps</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>
                          {formatime(
                            subscriptionInfo?.duration,
                            subscriptionInfo?.timeunit
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Pool Name</td>
                        <td>{subscriptionInfo?.pool_name}</td>
                      </tr>
                      <tr>
                        <td>Bundle Size</td>
                        <td>{subscriptionInfo?.bundle} GB</td>
                      </tr>
                      <tr>
                        <td>Expires In</td>
                        <td>
                          {subscriptionInfo.expires_in !== null
                            ? `${new Date(
                                subscriptionInfo.expires_in
                              ).toLocaleDateString()} ${new Date(
                                subscriptionInfo.expires_in
                              ).toLocaleTimeString()}`
                            : "not activated"}
                        </td>
                      </tr>
                      <tr>
                        <td>FUP Enabled</td>
                        <td>
                          {subscriptionInfo?.fup_enabled ? "true" : "false"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Dashboard>
        <ChangePlan
          show={changePlanDialog}
          handleShow={handleShowaddChangePlanDialog}
          packages={packages}
          currentPlanId={Number(subscriptionInfo?.planid)}
          accountNumber={accountInfo?.account_number}
          handleRefetch={setRequireFetch}
        />

          <GiveDiscount
          show={giveDiscountDialog}
          handleShow={handleGiveDiscountDialog}
          accountNumber={accountInfo?.account_number}
          handleRefetch={setRequireFetch}
        />
      </>
    )
  }
}
