import { apiSlice } from "../../../app/api/apiSlice"

export const dashboardAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDefaultDashboard: builder.query({
      query: (year) => {
        if (year) {
          return { url: `reseller/dashboard?year=${year}` }
        } else {
          return {
            url: `reseller/dashboard`,
          }
        }
      },
    }),
  }),
})

export const { useGetDefaultDashboardQuery } = dashboardAPiSlice
