import { createSlice } from "@reduxjs/toolkit"



const initialState = {
    isAuthenticated: false,
    refreshToken: null,
    access_token: null,
    expires_in: null,
    token_type: null,
    refresh_token_expires_in: null,
    user: null,
  }


const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
        console.log('this is the state', )
      const {
        user,
        access_token,
        expires_in,
        token_type,
      } = action.payload;
      state.user = user;
      state.access_token = access_token;
      state.expires_in = expires_in;
      state.token_type = token_type;
      state.isAuthenticated = true;
    },
    logOut: (state, action) => {
        state.user = null;
        state.access_token = null;
        state.expires_in = null;
        state.token_type = null;
        state.isAuthenticated = false;
        localStorage.removeItem('ch-token')
    },
  },
})

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.access_token;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
