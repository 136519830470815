import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useNavigate } from "react-router-dom"
import { useGetPPPUsersMutation } from "./pppUsersApiSlice"
import { useGetPppPackagesQuery } from "../../../app/api/sharedApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import CreateCustomer from "./CreateCustomer"

const columns = [
  { name: "itemId", description: "#" }, // account id
  { name: "account_number", description: "Account Number" },
  { name: "fullname", description: "FullName" },
  { name: "account_status", description: "Account Status" },
  { name: "subscription_status", description: "Subscription Status" },
  { name: "wallet_balance", description: "Wallet Balance" },
  { name: "plan_name", description: "Package" },
  { name: "expires_in", description: "Expires In" },
  { name: "email", description: "Email" },
  { name: "phone", description: "Phone" },
  { name: "customer_type", description: "Customer Type" },
]

const PPPUsers = () => {
  const navigate = useNavigate()
  const { data: packages, error: fetchErrors } = useGetPppPackagesQuery()
  const [getPPPUsers, { isLoading }] = useGetPPPUsersMutation()
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [selected, setSelected] = useState(null)
  const [requireFetch, setRequireFetch] = useState(true)

  // create user
  const [showAddDiaLog, handleShowaddDialog] = useState(false)

  const handleShowModalToAdd = () => {
    handleShowaddDialog(true)
  }


  const handleViewUserProfile = () => {
    navigate(`/reseller/ppp/customer/profile/${selected.account_number}`);
  }

  // fetch user
  useEffect(() => {
    if (requireFetch) {
      const fetchActiveUsers = async () => {
        try {
          const users = await getPPPUsers({
            pageSize: 100,
            pageNumber: 1,
          }).unwrap()
          setData(users.results)
        } catch (error) {
          setError(error?.data)
        }
      }
      fetchActiveUsers()
      return () => setRequireFetch(false)
    }
  }, [requireFetch, getPPPUsers])

  // selected row
  const handleSelect = (isSelected, row, index) => {
    setSelected(null)
    if (isSelected) {
      // insert
      const rawData = data.find((d) => d.id === index)
      setSelected({
        ...rawData,
      })
    }
  }

  useEffect(() => {}, [selected])

  // format list of users
  let itemId = 0
  const formatedData = data.map((user) => {
    itemId++
    return {
      ...user,
      itemId,
      wallet_balance: `KES ${Number(user.wallet_balance).toFixed(2)}`,
      fullname: `${user.firstname || ""} ${user.lastname || ""}`,
      expires_in:
        user.expires_in !== null
          ? `${new Date(user.expires_in).toLocaleDateString()} ${new Date(
              user.expires_in
            ).toLocaleTimeString()}`
          : "",
    }
  })

  // content
  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={true}
          isLoading={isLoading}
          onSelect={handleSelect}
          error={error || fetchErrors}
          selected={selected}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"PPP Users"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button
                  disabled={!!Boolean(selected)}
                  onClick={handleShowModalToAdd}
                  variant="primary"
                >
                  Add{" "}
                </Button>
              </div>
              <div>
                <Button
                  disabled={!!!Boolean(selected)}
                  onClick={handleViewUserProfile}
                  variant="success"
                >
                  View Profile{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      {/* create customer */}
      <CreateCustomer
        packages={packages}
        show={showAddDiaLog}
        handleShow={handleShowaddDialog}
        handleRefetch={setRequireFetch}
      />
    </>
  )
}
export default PPPUsers
