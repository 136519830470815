import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../pages/Auth/authSlice';

const RequireAuth = () => {
    const location = useLocation()

    const isLoginIn = useSelector(isAuthenticated);
    return (
        isLoginIn ? <Outlet /> : <Navigate to='/pages/signin' state={{from: location}} replace />
    )
}

export default RequireAuth;
