import { apiSlice } from "../../../app/api/apiSlice"

export const pppUsersAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPPPUsers: builder.mutation({
      query: (reseller) => ({
        url: "reseller/ppp/customers",
        method: "POST",
        body: { ...reseller },
      }),
    }),
    createPPPUsers: builder.mutation({
      query: (reseller) => ({
        url: "reseller/ppp/customer/add",
        method: "POST",
        body: { ...reseller },
      }),
    }),
    editPPPUserSubscription: builder.mutation({
      query: ({ accountNumber, ...plan }) => ({
        url: `reseller/ppp/customer/subscription/edit/${accountNumber}`,
        method: "PUT",
        body: { ...plan },
      }),
    }),
    activatePPPUser: builder.mutation({
        query: (accountNumber) => ({
          url: `reseller/ppp/customer/activate/${accountNumber}`,
          method: "POST",
          body: {  },
        }),
      }),
      deActivatePPPUser: builder.mutation({
        query: (accountNumber) => ({
          url: `reseller/ppp/customer/deactivate/${accountNumber}`,
          method: "POST",
          body: {  },
        }),
      }),
      giveDiscount: builder.mutation({
        query: (discount) => ({
          url: `reseller/ppp/customer/give-discount`,
          method: "POST",
          body: { ...discount },
        }),
      }),
    getPppUserProfile: builder.query({
      query: (accountNumber) => `/reseller/ppp/customer/${accountNumber}`,
    }),
  }),
})

export const {
  useGetPPPUsersMutation,
  useCreatePPPUsersMutation,
  useEditPPPUserSubscriptionMutation,
  useGetPppUserProfileQuery,
  useActivatePPPUserMutation,
  useDeActivatePPPUserMutation,
  useGiveDiscountMutation
} = pppUsersAPiSlice
