import { apiSlice } from "../../../app/api/apiSlice";

export const resellerInfoAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getResellerInfo: builder.query({
            query: (reseller)=> ({
                url: 'reseller/info',
            })
        }),

    })
});

export const { useGetResellerInfoQuery } =  resellerInfoAPiSlice;