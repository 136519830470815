import { apiSlice } from "../../../app/api/apiSlice"

export const hotspotAPiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHotspots: builder.query({
      query: () => ({
        url: "reseller/hotspots/list",
      }),
    }),
    getRouters: builder.query({
      query: () => ({
        url: "reseller/routers/list",
      }),
    }),
    createHotspot: builder.mutation({
      query: (hotspot) => ({
        url: "reseller/add/hotspot",
        method: "POST",
        body: { ...hotspot },
      }),
    }),

    updateHotspot: builder.mutation({
      query: (hotspot) => ({
        url: `reseller/edit/hotspot/${hotspot.id}`,
        method: "PATCH",
        body: {
          hotspotName: hotspot.hotspotName,
          interfaceName: hotspot.interfaceName,
          uploadMax: hotspot.uploadMax,
          downloadMax: hotspot.downloadMax,
          routerId: hotspot.routerId,
          landmark: hotspot.landmark,
        },
      }),
    }),
  }),
})

export const {
  useGetHotspotsQuery,
  useCreateHotspotMutation,
  useUpdateHotspotMutation,
  useGetRoutersQuery,
} = hotspotAPiSlice
