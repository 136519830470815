import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../pages/Auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl:  process.env.REACT_APP_API_URL,
    timeout: 5000,
    credentials: 'same-origin',
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.access_token
        if(token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json')
        }
        return headers;
    }
});


const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // token has  expired
    if(result?.error?.status === 403) {
        const token = localStorage.getItem('ch-token') || false;
        if(token) {
            const refreshResult = await baseQuery({
                url: '/auth/refresh',
                method: 'POST',
                body: {
                    refreshToken: token
                }
            }, api, extraOptions);
            if(refreshResult?.data) {
                const user = api.getState().auth.user;
                api.dispatch(setCredentials({ ...refreshResult.data, user }));
                localStorage.setItem('ch-token', refreshResult?.data?.refreshToken);
                // retry the original query with new access token 
                result = await baseQuery(args, api, extraOptions);
            }else {
                localStorage.removeItem('ch-token');
                api.dispatch(logOut());
            }

        }else {
            api.dispatch(logOut());
        }

    }
    return result;
}



export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})