import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Sidebar from "./Sidebar"
import IsLoading from "../components/IsLoading"

export default function Main() {
  const [isfetching, setIsFetching] = useState(true)
  const { pathname } = useLocation()

  const navigate = useNavigate()


  useEffect(() => {
    let mounted = true
    mounted && setIsFetching(false)
    if(pathname === '/') {
      navigate('/dashboard/hotspot/finance', {replace: true});
    }
    return () => (mounted = false)
  }, [])


  

  





  // scroll to top when switching pages
  window.scrollTo(0, 0)

  return (
    <>
      {isfetching ? (
        <div className="center-loading">
          <IsLoading />
        </div>
      ) : (
        <React.Fragment>
          <Sidebar />
          <Outlet />
        </React.Fragment>
      )}
    </>
  )
}
