import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Form, Card, Col, Row, Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import {
  useGetPackagesQuery,
  useCreatePackageMutation,
  useEditPackageMutation,
  useGetHotspotsQuery
} from "./packageApiSlice"
import MainGrid from "../../../../components/MainGrid"
import Dashboard from "../../../../components/Dashboard"
import { formatime, notify } from "../../../../helpers"
import CustomDialog from "../../../../components/CustomDialog"
import PackageForm from "./PackageForm"
import './style.css'

const columns = [
  { name: "count", description: "#" },
  { name: "id", description: "Id" },
  { name: "groupname", description: "Group Name", sort: true },
  { name: "bandwidth_max_upload", description: "Upload Speed" },
  { name: "bandwidth_max_donwload", description: "Download Speed" },
  { name: "bundle", description: "Bunde Size" },
  { name: "price", description: "Price" },
  { name: "hotspotname", description: "Hotspot Name" },
  { name: "duration", description: "Duration" },
]

export default function Packages() {
  const [hotspotId, setHotspotId] = useState(null);
  const { data: plans = [], fetchError, isLoading,refetch } = useGetPackagesQuery(hotspotId)
  const { data: hotspots = [] } = useGetHotspotsQuery()
  const [createPackage, { isLoading: isCreating }] = useCreatePackageMutation()
  const [editPackage, { isLoading: isEditing }] = useEditPackageMutation()

  const { user } = useSelector((state) => state.auth)
  const [requireFetch, setRequireFetch] = useState(false)
  const [selected, setSelected] = useState(null)
  const [error, setError] = useState(null)
  const [createError, setCreateError] = useState(null)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Create Hotspot Plan")
  const [initialValues, setInitialValues] = useState({
    bundle:  "",
    price: "",
    duration: "",
    timeunit: "",
    plan_categoryid: '',
    hotspotid: ""
  })





  // modal logic
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Create Hotspot Plan")
  }

  const handleShowModalToEdit = () => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Hotspot Plan")
    formik.setValues(initialValues)
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  // logic to save new package
  const saveAndCLose = async (values, resetForm) => {
    const plan = {
      companyid: Number(user.companyId),
      bundle: Number(values.bundle),
      price: Number(values.price),
      duration: Number(values.duration),
      timeunit: values.timeunit,
      hotspotid: Number(values.hotspotid),
      plan_categoryid: Number(values.plan_categoryid),
    }
    // do logic to save data
    if (action === "add") {
      try {
        await createPackage(plan).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        notify('Hotspot package added Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    } else {
      try {
        const editedPackage = await editPackage({packageId:selected.id, plan}).unwrap()
        resetForm()
        setShow(false)
        setCreateError(null)
        refetch()
        setInitialValues(editedPackage)
        notify('Hotspot package edited Successfully', false)
      } catch (error) {
        setCreateError(error.data)
        notify('Failed. Please try again', true)
      }
    }
  }

  // on mount, get list of packages
  useEffect(() => {
    if(requireFetch) {
      refetch()
    }
  }, [requireFetch])

  // row select - either delete or edit or view
  const handleSelect = (isSelected, row, index) => {
    setSelected(null)
    if(isSelected) {   // insert
      const rawData = plans.find(d=> d.id === index)
      setSelected({
        ...rawData
      })
      setInitialValues(prevState => ({
        ...prevState,
        bundle:  rawData?.bundle,
        price: rawData?.price,
        duration: rawData?.duration,
        timeunit: rawData?.timeunit,
        plan_categoryid: rawData?.plan_categoryid,
        hotspotid: rawData?.hotspotid
      }))
    }
  }

  const handleOnChange = (e)=> {
    const value = Number(e.target.value)
    if(value === -2) {
      setHotspotId(null)
      setRequireFetch(true)

    }else if (value > 0 ) {
      setHotspotId(value)
      setRequireFetch(true)
    }
  }

  const onRefresh = ()=> {
    refetch()
  }

  useEffect(()=> {
  }, [selected])

  // columns
  let count = 0
  const formatedData = plans.map((plan) => {
    count ++
    return {
      ...plan,
      count: count,
      bandwidth_max_upload: `${plan.upload_max_speed} ${plan.speedunit}PS`,
      bandwidth_max_donwload: `${plan.download_max_speed} ${plan.speedunit}PS`,
      price: `${Number(plan.price).toFixed(2)} ${plan.currency}`,
      duration: formatime(plan.duration, plan.timeunit),
      bundle: `${plan.bundle} GB`
    }
  })

  const options = hotspots.map(h=> {
    return (
      <option key={h.id} value={h.id}>
     {h.name}
    </option>
    )
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={true}
          onSelect={handleSelect}
          isLoading={isLoading || isEditing}
          error={error}
          selected={selected}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )
  return (
    <React.Fragment>
      <Dashboard title="Hotspot Packages">
        <Row className="package-page-header">
          <Col  lg={4} md={6} xs={12} className="filter-layout">
          <h5 className="filterByLabel">Filter By Hotspot: </h5>
          <div className="filter-by-container" xs={8}>
            <Form.Group>
              <Form.Select 
              value={hotspotId}
              onChange={handleOnChange}
              name="hotspot">
                <option key={-1} value={-1} >Select Hotspot</option>
                <option key={-2} value={-2} >All</option>
                {options}
              </Form.Select>
            </Form.Group>
          </div>
          </Col>
          <Col >
            <div className="align-items-right btn-group">
              <div>
                <Button disabled={!!Boolean(selected)} onClick={handleShowModalToAdd} variant="primary">
                  Add{" "}
                </Button>
              </div>
              {/* <div>
                <Button disabled variant="secondary">Add & Close</Button>
              </div> */}
              <div>
                <Button disabled={!!!Boolean(selected)} onClick={handleShowModalToEdit} variant="success">
                  Edit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <Form method="post" onSubmit={formik.handleSubmit}>
        <CustomDialog
          show={show}
          title={title}
          onCLose={handleCloseDialog}
          onSave={formik.handleSubmit}
          isLoading={isCreating}
          size={'lg'}
          
        >
          <PackageForm formik={formik} error={createError} hotspots={hotspots} defaultData={selected} />
        </CustomDialog>
      </Form>
    </React.Fragment>
  )
}



// validation
const validationSchema = Yup.object({
  price: Yup.number().required("Price is required"),
  plan_categoryid: Yup.number().required("category is required"),
  duration: Yup.number().required("duration is required"),
  timeunit: Yup.string().required("time unit must be specified"),
  hotspotid: Yup.number().required("hotspot must be selected"),
  bundle: Yup.number().required("bundle is required"),
})
