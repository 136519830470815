import React from 'react';
import { Col, Row, Form } from "react-bootstrap"
import './style.css'

const FilterByYear = ({options, value, handleYearOnChange}) => {
    return (
        <Row className="mt-3">
        <Col md={6}  className="filterby">
          <h5 className="filterByLabel">Filter By Year: </h5>
          <div className="filter-by-container" xs={8}>
            <Form.Group>
              <Form.Select 
              value={value}
              onChange={handleYearOnChange} 
              name="year">
                <option key={1} value={1} >Select Year</option>
                {options}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
      </Row>
    );
}

export default FilterByYear;
