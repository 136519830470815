import { apiSlice } from "../../../../app/api/apiSlice";

export const packageApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPackages: builder.query({
            query: (hotspotId)=> {
                if(hotspotId) {
                    return {url: `reseller/packages/list?hotspotId=${hotspotId}`}
                }
                return {url: `reseller/packages/list`}
            }
        }),
        getHotspots: builder.query({
            query: ()=> {
                return {url: `reseller/hotspots/list`}
            }
        }),
        createPackage: builder.mutation({
            query: plan => ({
                url: 'reseller/package',
                method: 'POST',
                body: {...plan}
            })
        }),
        editPackage: builder.mutation({
            query: ({packageId, plan}) => {
                return {
                    url: `reseller/package/${packageId}`,
                method: 'PUT',
                body: {...plan}
                }
            }
        })
    })
});

export const {useCreatePackageMutation, useGetPackagesQuery, useEditPackageMutation, useGetHotspotsQuery} = packageApiSlice;