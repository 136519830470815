import React from "react"
import { Card } from "react-bootstrap"
const SummaryCard = ({ title, value, color }) => {
  return (
    <>
        <Card className="card-one">
          <Card.Body className="p-3">
            <div className="d-block fs-40 lh-1 text-primary mb-1">
              <i className="ri-wallet-2-line"></i>
            </div>
            <h5 className={`card-value mb-0 ls--1 fs-20 ${color}`}>
              {value}
            </h5>
            <label className="d-block mb-1 fw-medium text-dark">{title}</label>
          </Card.Body>
        </Card>
      
    </>
  )
}

export default SummaryCard
