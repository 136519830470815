import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import {
  useGetHotspotsQuery,
  useCreateHotspotMutation,
  useUpdateHotspotMutation,
  useGetRoutersQuery,
} from "./hotspotApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { notify } from "../../../helpers"
import HotspotForm from "./HotspotForm"

const columns = [
  { name: "count", description: "#" },
  { name: "id", description: "ID" },
  { name: "name", description: "Hotspot Name" },
  { name: "portname", description: "Port Name" },
  { name: "upload_max_speed", description: "Upload Max Speed" },
  { name: "download_max_speed", description: "Download Max Speed" },
  { name: "routerid", description: "Router Id" },
  { name: "landmark", description: "Landmark" },
]

const Hotspots = () => {
  // state
  const {
    data: hotspots,
    error: hotspotErrors,
    isLoading: isLoadingHotspots,
    refetch,
  } = useGetHotspotsQuery()
  const {
    data: routers,
    error: routerErrors,
    isLoading: isFetchingRouters,
  } = useGetRoutersQuery()
  const [createHotspot, { isLoading: isCreating }] = useCreateHotspotMutation()
  const [updateHotspot, { isLoading: isEditing }] = useUpdateHotspotMutation()
  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Hotspot")
  const [inProgress, setInprogress] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    hotspotName: "",
    interfaceName: "",
    uploadMax: "",
    downloadMax: "",
    routerId: "",
    landmark: "",
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  // handlers
  const handleShowModalToAdd = () => {
    setShow(true)
    setAction("add")
    setTitle("Add Hotspot")
    setFormErrors(null)
  }

  const handleShowModalToEdit = () => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Hotspot")
    setFormErrors(null)
    formik.setValues(initialValues)
  }

  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addHotspot = async (values, resetForm) => {
    try {
      await createHotspot(values).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("Hotspot added Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error.data)
      notify("Failed. Please try again", true)
    }
  }

  const editHotspot = async (values, resetForm) => {
    try {
      await updateHotspot({ ...values, id: selected.id }).unwrap()
      setShow(false)
      setFormErrors(null)
      refetch()
      notify("Hotspot updated Successfully", false)
      resetForm()
    } catch (error) {
      setFormErrors(error.data)
      notify("Failed. Please try again", true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if (action === "add") {
      await addHotspot(values, resetForm)
    } else {
      await editHotspot(values, resetForm)
    }
  }

  const onSelect = (isSelected, row, index) => {
    setSelected(null)
    if (isSelected) {
      // insert
      const rawData = hotspots.find((d) => d.id === index)
      setSelected({
        ...rawData,
      })
      // store the values for editing
      setInitialValues((prevState) => ({
        ...prevState,
        hotspotName: rawData?.name,
        interfaceName: rawData?.portname,
        uploadMax: rawData?.upload_max_speed,
        downloadMax: rawData?.download_max_speed,
        routerId: rawData?.routerid,
        landmark: rawData?.landmark,
      }))
    }
  }

  useEffect(() => {}, [selected])

  // on state changes
  useEffect(() => {
    if (isCreating || isEditing) {
      setInprogress(true)
    } else {
      setInprogress(false)
    }
  }, [isCreating, isEditing])

  // format to render
  let count = 0
  const formatedData = (hotspots || []).map((hotspot) => {
    count++
    return {
      ...hotspot,
      count,
      upload_max_speed: `${hotspot.upload_max_speed} ${hotspot.speedunit}PS`,
      download_max_speed: `${hotspot.download_max_speed} ${hotspot.speedunit}PS`,
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={true}
          onSelect={onSelect}
          selected={selected}
          isLoading={isLoadingHotspots || isFetchingRouters}
          error={hotspotErrors || routerErrors}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Hotspots"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button
                  disabled={!!Boolean(selected)}
                  onClick={handleShowModalToAdd}
                  variant="primary"
                >
                  Add{" "}
                </Button>
              </div>
              <div>
                <Button
                  disabled={!!!Boolean(selected)}
                  onClick={handleShowModalToEdit}
                  variant="success"
                >
                  Edit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <HotspotForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={inProgress}
        errors={formErrors}
        routers={routers}
      />
    </>
  )
}

export default Hotspots

// validation
const validationSchema = Yup.object({
  hotspotName: Yup.string().required("hotspot name is required"),
  interfaceName: Yup.string().required("interfaceName/Bridge Name is required"),
  uploadMax: Yup.number().required("uploadMax is required"),
  downloadMax: Yup.number().required("downloadMax must be specified"),
  routerId: Yup.number().required("routerId must be specified"),
  landmark: Yup.string().required("landmark name is required"),
})
