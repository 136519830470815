import { Card, Col, Row, Table, Form } from "react-bootstrap"
import Dashboard from "../../components/Dashboard"
import MainGrid from "../../components/MainGrid"
import React, { useEffect, useState } from "react"
import { useGetBillingHistoryQuery, useGetBillingSummaryQuery } from "./billingApiSlice"
import { formatMoney } from "../../helpers"
import FilterByYear from "../../components/FilterByYear"

const columns = [
  { name: "date", description: "Billing Date" }, // account id
  { name: "description", description: "Description" },
  { name: "hotspot", description: "Hotspot Total" },
  { name: "ppp", description: "PPP Total" },
  { name: "collection", description: "Total Collected" },
  { name: "totalAmount", description: "Total Usage" },
  { name: "vat", description: "VAT (16%)" },
  { name: "totalBill", description: "Total Bill" },
  { name: "status", description: "Payment Status" },
  { name: "download", description: "Download" },
]

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
}

const date = new Date()
const startYear = 2023
const currentYear = date.getFullYear();
const filteryears = []

for(let x=startYear; x<=currentYear; x++) {
  filteryears.push(x)
}

const Billing = () => {
  const [year, setYear] = useState(currentYear)
  const { data = [], error, isLoading,refetch } = useGetBillingHistoryQuery(year)
  const { data: currentBill = {} } = useGetBillingSummaryQuery()

  const {currentMonth,totalCollection, commission, vat, totalUsage, tax, totalBill } = currentBill


  // listen for changes
  useEffect(()=> {
    // trigger fetch
    refetch()

  }, [year])

  const handleYearOnChange =(e)=> {
    const value = Number(e.target.value);
    if(value === 1 ) {
      setYear(currentYear)
    }else {
      setYear(value)
    }
  }

  //format table data
  const formatedData = data.map((item, idx) => {
    const totalCollection = Number(item.hotspot_total) + Number(item.ppp_total)
    let totalUsage = totalCollection * (item.commission / 100)
    let vat = (item.vat / 100) * totalUsage
    let totalBill = formatMoney(totalUsage + vat)
    return {
      ...item,
      id: idx,
      vat: formatMoney(vat),
      description: `Invoice for ${months[item.month]} ${item.year}`,
      hotspot: formatMoney(item.hotspot_total),
      ppp: formatMoney(item.ppp_total),
      download: <a href="">CSV</a>,
      date: new Date(item.billing_date).toDateString(),
      totalAmount: formatMoney(totalUsage),
      collection: formatMoney(totalCollection),
      totalBill: totalBill,
    }
  })
  // content
  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading}
          error={error }
        />
      </Card.Body>
    </Card>
  )

  const options = filteryears.map(year=> {
    return (
      <option key={year} value={year}>
     {year}
    </option>
    )
  })
  return (
    <Dashboard title={"Billing"}>
      <Card>
        <Card.Header>
          <h5>Latest Billing Summary </h5>
        </Card.Header>
        <Card.Body>
          <Row className="">
            <Col xs={12} md={3}>
              <Card className="portal-card">
                <Card.Header className="text-center">Payment Due</Card.Header>
                <Card.Body className="portal-card-body">
                  <h6 className="text-warning">{currentMonth || 0}</h6>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="portal-card">
                <Card.Header className="text-center">
                  Total Usage ({commission})
                </Card.Header>
                <Card.Body className="portal-card-body">
                  <Table bordered={false} striped={false} borderless>
                    <tbody>
                      <tr>
                        <td>Total Collected</td>
                        <td>{formatMoney(totalCollection)}</td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>{commission}</td>
                      </tr>
                      <hr></hr>
                      <tr>
                        <td>Total Usage</td>
                        <td>{formatMoney(totalUsage)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="portal-card">
                <Card.Header className="text-center">
                  VAT {vat} (Kenya)
                </Card.Header>
                <Card.Body className="portal-card-body">
                  <Table bordered={false} striped={false} borderless>
                    <tbody>
                      <tr>
                        <td>Total Usage</td>
                        <td>{formatMoney(totalUsage)}</td>
                      </tr>
                      <tr>
                        <td>VAT</td>
                        <td>{vat}</td>
                      </tr>
                      <hr></hr>
                      <tr>
                        <td>Tax</td>
                        <td>{formatMoney(tax)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="portal-card">
                <Card.Header className="text-center">Estimated Due</Card.Header>
                <Card.Body className="portal-card-body">
                  <h6 className="text-success">{totalBill}</h6>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className="mt-5">
        <Col>
          <h3>Billing History</h3>
        </Col>
      </Row>

      <FilterByYear options={options} value={year} handleYearOnChange={handleYearOnChange} />

      <Row className="mt-2">
        <Col>{content}</Col>
      </Row>
    </Dashboard>
  )
}

export default Billing
