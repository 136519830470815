import { apiSlice } from "../../../../app/api/apiSlice";

export const packageApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPppPackages: builder.query({
            query: ()=> `reseller/ppp/packages`,
            providesTags:(results)=> [{ type: 'PppPackage', id: results.id }]
        }),
        createPppPackage: builder.mutation({
            query: plan => ({
                url: 'reseller/ppp/package/add',
                method: 'POST',
                body: {...plan}
            })
        }),
        editPppPackage: builder.mutation({
            query: ({packageId, plan}) => {
                return {
                    url: `reseller/ppp/package/${packageId}`,
                method: 'PUT',
                body: {...plan}
                }
            }
        })
    })
});

export const {useCreatePppPackageMutation, useEditPppPackageMutation, useGetPppPackagesQuery} = packageApiSlice;