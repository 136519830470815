import React from "react"
import { Modal, Button } from "react-bootstrap"

const CustomDialog = ({ title, onCLose, onSave, show, isLoading, children, size }) => {
  return (
    <Modal show={show} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton onHide={onCLose}>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} variant="warning" onClick={onCLose}>Close</Button>
        <Button  disabled={isLoading} type="submit" onClick={onSave}>{isLoading ? 'Saving...': 'Save'}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomDialog
