import React from "react"
import CustomDialog from "../../../components/CustomDialog"
import { Form, Col, Card, Row, Alert } from "react-bootstrap"

const RouterForm = ({
  show,
  title,
  handleCloseDialog,
  formik,
  isLoading,
  errors,
}) => {
  return (
    <CustomDialog
      show={show}
      title={title}
      onCLose={handleCloseDialog}
      onSave={formik.handleSubmit}
      isLoading={isLoading}
      size={"lg"}
    >
      <Form method="post" onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                {errors && errors?.message && (
                  <Alert variant="danger">{errors?.message}</Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>IP Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ipAddress"
                    value={formik.values.ipAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="ipAddress"
                  />
                  {formik.errors.ipAddress && formik.touched.ipAddress && (
                    <Form.Text className="text-danger">
                      {formik.errors.ipAddress}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                  />
                  {formik.errors.description && formik.touched.description && (
                    <Form.Text className="text-danger">
                      {formik.errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="location"
                  />
                  {formik.errors.location && formik.touched.location && (
                    <Form.Text className="text-danger">
                      {formik.errors.location}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="landmark"
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="landmark"
                  />
                  {formik.errors.landmark && formik.touched.landmark && (
                    <Form.Text className="text-danger">
                      {formik.errors.landmark}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </CustomDialog>
  )
}

export default RouterForm
