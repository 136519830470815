import React, { useState, useEffect } from "react"
import Dashboard from "../../../../components/Dashboard"
import { useGetTranferReportMutation } from "./transferReportApiSlice"
import { useSelector } from "react-redux"
import { Col, Row, Card } from "react-bootstrap"
import MainGrid from "../../../../components/MainGrid"
import { formatTimeInZ, formatime } from "../../../../helpers"

const TransferReport = () => {
  const [getTranferReport, { isLoading }] = useGetTranferReportMutation()
  const { user } = useSelector((state) => state.auth)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  //
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const transfers = await getTranferReport({
          resellerId: user.companyId,
          pageSize: 50,
          pageNumber: currentPage
        }).unwrap()


        setData(transfers);
        setCurrentPage(transfers.currentPage);
      } catch (error) {
        setError(error?.data)
      }
    }
    fetchReport()
  }, [currentPage, user.companyId, getTranferReport])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const columns = [
    { name: "id", description: "#" },
    { name: "amount", description: "Amount" },
    { name: "channel", description: "Channel" },
    { name: "transferdatetime", description: "Transfer Date Time" },
    { name: "phone", description: "Payee Phone" },
    { name: "duration", description: "Internet Duration" },
    { name: "status", description: "Payment Status" },
    { name: "transfertype", description: "Transfer Type" },
    { name: "hotspotname", description: "Hotspot Name" },
    { name: "hotspotid", description: "Hotspot ID" },
  ];

  const formatedData = (data?.results || []).map(tx => {
    return {
        ...tx, 
        amount: `${tx.currency} ${Number(tx.amount).toFixed(2)}`,
        duration: formatime(tx.duration, tx.timeunit),
        status: tx.status === 2 ? 'completed': 'failed',
        transferdatetime: formatTimeInZ(tx.transferdatetime)
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isSelectable={false}
          isLoading={isLoading}
          error={error}
          onPageChnage = {handlePageChange}
          pagination={{
            currentPage: data?.currentPage,
            pageSize: data?.pageSize,
            totalItems: data?.totalItems,
            next: data?.next,
            previous: data?.previous,
            pagesTotal: data?.pagesTotal
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Hotspot Transfer Report"}>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default TransferReport
