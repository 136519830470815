import React from "react";
import { Pie } from "react-chartjs-2"
import "chart.js/auto"
import { Card } from "react-bootstrap";
import { generateRandomColors } from "../../../helpers/randomColors";

const ActiveUsersPerHotspot = ({data}) => {
  const labels = (data|| []).map(d => {
    return `${d.name}`
  })
  const cxData = (data|| []).map(d => {
    return d.total
  })
  const colors = generateRandomColors(labels.length);


  const dataPie = {
    labels: labels,
    datasets: [
      {
        data: cxData,
        backgroundColor: colors
      },
    ],
  }

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  }
  return  (
    <Card className="ml-5">
      <Card.Header>
        <Card.Title as="h6">Total User Count Per Hotspot</Card.Title>
      </Card.Header>
      <Card.Body>
        <Pie data={dataPie} options={optionPie} className="ht-350"  />
      </Card.Body>
    </Card>
  )
}

export default ActiveUsersPerHotspot
