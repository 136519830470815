import React from "react"

import { Form, Table, Button } from "react-bootstrap"
import IsLoading from "./IsLoading"
import DefaultPagination from "./DefaultPagination"

const MainGrid = ({
  columns,
  tableData,
  isSelectable,
  pagination,
  onSelect,
  error,
  isLoading,
  onPageChnage,
  selected,
  onRefresh,
}) => {
  const canBeSelected = isSelectable || false
  const canBePaginated = pagination || false

  const handleSelect = (isSelected, row, rowId) => {
    onSelect(isSelected, row, rowId)
  }

  const headers = columns.map((column, index) => (
    <th key={index}>{column.description}</th>
  ))

  const rows = tableData.map((row) => (
    <tr key={row.id}>
      {canBeSelected && (
        <td>
          <Form.Check
            type="checkbox"
            checked={selected && selected.id === row.id}
            id={row.id}
            onChange={(e) => handleSelect(e.target.checked, row, row.id)}
          />
        </td>
      )}
      {columns.map((column, index) => (
        <td key={index}>{row[column.name]}</td>
      ))}
      {onRefresh && <td></td>}
    </tr>
  ))

  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            {canBeSelected && (
              <th>
                <Form.Check // prettier-ignore
                  type="checkbox"
                  disabled
                />
              </th>
            )}
            {headers}
            {onRefresh && (
              <th>
                <Button onClick={onRefresh} variant="outline-warning">
                  <i className="ri-restart-line"></i>
                </Button>
              </th>
            )}
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </Table>
      {isLoading && <IsLoading />}
      {error && <h6>{error?.message || "error occured"}</h6>}
      {rows.length === 0 && <h6 className="mt-4">No data found</h6>}
      {canBePaginated && (rows || []).length > 0 && (
        <DefaultPagination
          pagination={pagination}
          onPageChnage={onPageChnage}
        />
      )}
    </>
  )
}

// MainGrid.propTypes = {
//     columns: PropTypes.checkPropTypes(),
//     tableData: PropTypes.array(),
// };

export default MainGrid
