import React from "react"
import { Pagination } from "react-bootstrap"

const DefaultPagination = ({ pagination, onPageChnage }) => {
  const { currentPage, next, previous, pagesTotal } =
    pagination

  const handlePageClick = (page) => {
    if (page !== currentPage) {
        onPageChnage(page)
    }
  }

  const renderPaginationItems = () => {
    const items = []

    // add elipsis if needed
    for (let page = 1; page <= pagesTotal; page++) {
        if(page === 10){
            break
        }
      items.push(
        <Pagination.Item
          active={page === currentPage}
          key={page}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </Pagination.Item>
      )
    }

    if (pagesTotal > 10) {
        items.push(<Pagination.Ellipsis key="elipssis-end" />)
      }

      return items;
  
  }

  return (
    <div className="pagination">
      <h6>showing {currentPage} of {pagesTotal}</h6>
      <Pagination>
        <Pagination.First onClick={()=> handlePageClick(1)} disabled={currentPage ===1 } />
        <Pagination.Prev onClick={()=> handlePageClick(previous)} disabled={currentPage === 1 || currentPage <0 } />
        {renderPaginationItems()}
        <Pagination.Next onClick={()=> handlePageClick(next)} disabled={currentPage === pagesTotal} />
        <Pagination.Last onClick={()=> handlePageClick(pagesTotal)} disabled={currentPage ===pagesTotal } />
      </Pagination>
    </div>
  )
}

export default DefaultPagination
