import React, { useEffect, useState } from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"
import {
  useGetPackageCategoriesQuery,
} from "../../../../app/api/sharedApiSlice"

const PackageForm = ({ formik, error, hotspots }) => {
  const [hotspotError, setHotSpotError] = useState(null)

  const { data: planGroup } = useGetPackageCategoriesQuery()

  const hotspotOptions = (hotspots || []).map((hotspot) => {
    return (
      <option key={hotspot.id} value={hotspot.id}>
        {hotspot.name}
      </option>
    )
  })

  const categoryOption = (planGroup || []).map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.groupname}
      </option>
    )
  })

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {error && error?.message && (
              <Alert variant="danger">{error?.message}</Alert>
            )}
            {hotspotError && hotspotError?.message && (
              <Alert variant="danger">{hotspotError?.message}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Plan Category</Form.Label>
              <Form.Select
                value={formik.values.plan_categoryid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="plan_categoryid"
              >
                <option>Select Plan Category</option>
                {categoryOption}
              </Form.Select>
              {formik.errors.plan_categoryid && formik.touched.plan_categoryid && (
                <Form.Text className="text-danger">
                  {formik.errors.plan_categoryid}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Hotspot</Form.Label>
              <Form.Select
                value={formik.values.hotspotid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="hotspotid"
              >
                <option>Select hotspot</option>
                {hotspotOptions}
              </Form.Select>
              {formik.errors.hotspotid && formik.touched.hotspotid &&  (
                <Form.Text className="text-danger">
                  {formik.errors.hotspotid}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="duration"
              />
              {formik.errors.duration && formik.touched.duration && (
                <Form.Text className="text-danger">
                  {formik.errors.duration}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="hotspot">
              <Form.Label>Time Unit</Form.Label>
              <Form.Select
                value={formik.values.timeunit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="timeunit"
              >
                <option>Open this select menu</option>
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </Form.Select>
              {formik.errors.timeunit && formik.touched.timeunit  && (
                <Form.Text className="text-danger">
                  {formik.errors.timeunit}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="price"
              />
              {formik.errors.price && formik.touched.price && (
                <Form.Text className="text-danger">
                  {formik.errors.price}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Bundle Size in GB</Form.Label>
              <Form.Control
                type="text"
                placeholder="bundle"
                value={formik.values.bundle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="bundle"
              />
              {formik.errors.bundle && formik.touched.bundle && (
                <Form.Text className="text-danger">
                  {formik.errors.bundle}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PackageForm
