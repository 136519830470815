import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetDefaultDashboardQuery } from "./dashboardApiSlice"
import { useSelector } from "react-redux"
import { Col, Row, } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import IncomePerMonth from "./incomePerMonth"
import IncomeInLast7Days from "./IncomeInLast7Days"
import IncomePerPackage from "./incomePerPackage"
import IncomePerHotspot from "./incomePerHotspot"
import ActiveUsersPerHotspot from "./activeUsersPerHotspot"
import MostUsedPackage from "./mostUsedPackage"
import SummaryCard from "./../SummaryCard"
import { formatMoney } from "../../../helpers"
import FilterByYear from "../../../components/FilterByYear"


const date = new Date()
const startYear = 2023
const currentYear = date.getFullYear();
const filteryears = []

for(let x=startYear; x<=currentYear; x++) {
  filteryears.push(x)
}

const FinanceDashboard = () => {
  const [year, setYear] = useState(currentYear)
  const { data = [], error, isLoading,refetch } = useGetDefaultDashboardQuery(year)
  const { user } = useSelector((state) => state.auth)

  const options = filteryears.map(year=> {
    return (
      <option key={year} value={year}>
     {year}
    </option>
    )
  })

  const handleYearOnChange =(e)=> {
    const value = Number(e.target.value);
    if(value === 1 ) {
      setYear(currentYear)
    }else {
      setYear(value)
    }
  }

  //
  useEffect(() => {
    refetch()
  }, [year])

  // const totalIncome = (data?.totalIncome || []).length > 0 ? data?.totalIncome[0]?.totalincome : 0;
  const incomeToday = (data?.incomeToday || []).length > 0 ? data?.incomeToday[0]?.totalamount : 0;
  const totalActiveUsers = (data?.totalActiveUsers || []).length> 0  ? data?.totalActiveUsers[0]?.totalcount : 0;
  const totalIncomeThisMonth = (data?.totalIncomeThisMonth || []).length > 0 ? data?.totalIncomeThisMonth[0]?.total : 0;
  const totalBill = (data?.totalBill || []).length > 0 ? data?.totalBill[0]?.total : 0;

  return (
    <>
      <Dashboard title={"Hostpot Dashboard"}>
      <FilterByYear options={options} value={year} handleYearOnChange={handleYearOnChange} />
        <Row className="mt-3">
          <Col xs="12" md={7}>
            <Row>
              {/* <Col xs="6" className="my-2" sm>
                <SummaryCard title={'Total Income'} color={'text-success'} value={`${formatMoney(Number(totalIncome))}`} />
              </Col> */}
              <Col xs="6" className="my-2" sm>
              <SummaryCard title={'Total Active Users'} color={''} value={totalActiveUsers} />
              </Col>
              <Col xs="6" className="my-2" sm>
              <SummaryCard title={'Income This Month'} color={'text-secondary'} value={`${formatMoney(Number(totalIncomeThisMonth))}`} />
              </Col>
              <Col xs="6" className="my-2" sm>
              <SummaryCard title={'Estimated Bill This Month'} color={'text-warning'} value={`${formatMoney(Number(totalBill))}`} />
              </Col>
              <Col xs="6" className="my-2" sm>
              <SummaryCard title={'Income Today'} color={'text-success'} value={`${formatMoney(Number(incomeToday))}`} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm className="card-one p-3">
                <IncomePerMonth data={data?.incomePerMonth} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12" md={4}>
                <IncomePerHotspot data={data?.incomePerHotspot} />
              </Col>
              <Col xs="12" md={4}>
                <ActiveUsersPerHotspot data={data?.activeUsersPerHotspot} />
              </Col>
              <Col xs="12" md={4}>
                <MostUsedPackage data={data?.mostUsedPackage} />
              </Col>  
            </Row>
          </Col>
          <Col xs="12" md={5}>
            <Row>
              <Col xs="12" className="card-one p-3">
                <IncomeInLast7Days data={data?.incomeInLast7Days} />
              </Col>
              <Col xs="12" className="card-one p-3">
                <IncomePerPackage data={data?.incomePerPackage} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default FinanceDashboard
