import React, { useState, useEffect } from "react"
import Dashboard from "../../../../components/Dashboard"
import { useGetActiveUsersQuery, useUpdateAccountStatusMutation } from "./activeUsersApiSlice"
import { Col, Row, Card, Form, Button, Modal } from "react-bootstrap"
import MainGrid from "../../../../components/MainGrid"
import { formatime, notify } from "../../../../helpers"



const columns = [
  { name: "count", description: "#" },
  { name: "id", description: "Customer Id" },
  { name: "mac", description: "Device" },
  { name: "phone", description: "Phone Number" },
  { name: "status", description: "Status" },
  { name: "plan", description: "Plan Duration" },
  { name: "speed", description: "Speed (MB/s)" },
  { name: "bundle", description: "Bundle" },
  { name: "valid_unitl", description: "Expires In" },
  { name: "paid_on", description: "Paid On" },
  { name: "name", description: "Hotspot Name" },
  { name: "hotspotid", description: "Hotspot ID" },
  { name: "routerid", description: "Router ID" },
];

const ActiveUsers = () => {
  const [phone, setPhone] = useState(null);
  const [selected, setSelected] = useState(null)
  const [action, setAction] = useState("")
  const [title, setTitle] = useState("")
  const [show, setShow] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useGetActiveUsersQuery({ pageSize: 50, pageNumber: currentPage, phone })
  const [updateAccountStatus, { isLoading: isUpdating }] = useUpdateAccountStatusMutation()

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSelect = (isSelected, row, index) => {
    setSelected(null)
    if(isSelected) {   // insert
      console.log(row)
      const rawData = data?.results.find(d=> d.customerid === index)
      setSelected({
        ...rawData,
        id: rawData?.customerid
      })
    }
  }

  const onPhoneChange = (e)=> {
    setPhone(e.target.value)
  }

  const handleActivateModalPopup = () => {
    setShow(true)
    setAction('Activate')
    setTitle('Are you sure you want to activate this user')
  }

  const handleDeactivateModalPopup = () => {
    setShow(true)
    setAction('Deactivate')
    setTitle('Are you sure you want to deactivate this user')
  }

  const handleCloseDialog = () => {
    setShow(false)
  }

  const processAction = async () => {
    const params = {
      mac: selected.mac,
      customerid: selected.customerid,
      packageid: selected.packageid,
      companyid: selected.companyid


    }
    let message;

    if(action === 'Activate') {
      params.action = 'activate'
      message = 'User Activated Successfully'
      
    }else {
      params.action = 'deactivate'
      message = 'User Deactivated Successfully'
    }

    // send post request to process this request
    try {
      await updateAccountStatus(params).unwrap()
      notify(message, false)
    } catch (error) {
      notify('Failed to update user status. Please try again Later', true)
      
    }finally {
      refetch()
      setShow(false)
      setSelected(null)
    }
    
  }


// on page change
  useEffect(()=> {
    refetch()
  }, [currentPage])


let count = 0
  const formatedData = (data?.results || []).map(user => {
    count ++
    return {
        ...user, 
        count,
        id: user.customerid,
        plan: formatime(user.duration, user.timeunit),
        bundle: `${user.bundle} ${user.bundleunit}`,
        speed: `${user.download_max_speed}/ ${user.upload_max_speed}`,
        valid_unitl: user.valid_until ? `${new Date(user.valid_until).toLocaleDateString() } ${new Date(user.valid_until).toLocaleTimeString() }` : '',
        paid_on: user.paid_on ? `${new Date(user.paid_on).toDateString() } ${new Date(user.paid_on).toLocaleTimeString() }` : '',
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isLoading={isLoading}
          error={error}
          onPageChnage={handlePageChange}
          isSelectable={true}
          onSelect={handleSelect}
          selected={selected}
          pagination={{
            currentPage: data?.currentPage,
            pageSize: data?.pageSize,
            totalItems: data?.totalItems,
            next: data?.next,
            previous: data?.previous,
            pagesTotal: data?.pagesTotal
          }}
        />
      </Card.Body>
    </Card>
  )
  return (
    <>
      <Dashboard title={"Hotspot Users"}>
      <Row className="package-page-header">
          <Col  lg={4} md={6} xs={12} className="filter-layout">
          <h5 className="filterByLabel">Search By Phone: </h5>
          <div className="filter-by-container" xs={8}>
            <Form.Group>
            <Form.Control
                type="text"
                placeholder="phone Number"
                value={phone}
                onChange={onPhoneChange}
                name="phone"
              />
            </Form.Group>
          </div>
          </Col>
          <Col >
            <div className="align-items-right btn-group">
              <div>
                <Button disabled={!(selected?.status === 'inactive')}  onClick={handleActivateModalPopup} variant="primary">
                  Active{" "}
                </Button>
              </div>
              {/* <div>
                <Button disabled variant="secondary">Add & Close</Button>
              </div> */}
              <div>
                <Button disabled={!(selected?.status === 'active')} onClick={handleDeactivateModalPopup}  variant="danger">
                  Deactivate
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>

      <Modal show={show} size={'lg'} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton onHide={handleCloseDialog}>
          <Modal.Title id="contained-modal-title-vcenter">{`User ${action === 'Activate' ? 'Activation': 'Deactivation'}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{title}</p>
          </Modal.Body>
        <Modal.Footer>
          <Button disabled={isUpdating} variant="warning" onClick={handleCloseDialog}>Close</Button>
          <Button  disabled={isUpdating} onClick={processAction}>{isUpdating ? `${action}...`: action}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )


}

export default ActiveUsers
