import React, { useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { Row, Col, Table, Card } from "react-bootstrap"
import Prism from "prismjs"
import SummaryCard from "../SummaryCard"
import { formatMoney } from "../../../helpers"
import IncomePerMonth from "./incomePerMonth"
import { useGetPPPDashboardQuery } from "./dashboardApiSlice"

const PPPDashboard = () => {
  const { data, isLoading, isError } = useGetPPPDashboardQuery()
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  const totalUsers = data?.totalUsers[0].total || 0
  const expiredUsers = data?.totalExpiredUsers[0].total || 0
  const activeUsers = data?.totalActiveUsers[0].total || 0
  const incomeThisMonth = data?.incomeThisMonth[0].total || 0
  const incomeToday = data?.incomeToday[0].total || 0

  const listOfExpiredUsers = (data?.expiresUsers || []).map((user) => {
    return (
      <tr key={user?.id}>
        <th scope="row">{user?.account_number}</th>
        <td>{`${user?.firstname || ""} ${user?.lastname || ""}`}</td>
        <td>{user?.plan_name}</td>
        <td>{`${new Date(user?.expires_in).toLocaleString()}`}</td>
        <td>{user?.phone}</td>
      </tr>
    )
  })

  const listOfInactiveUsers = (data?.inactiveUsers || []).map((user) => {
    return (
      <tr key={user?.id}>
        <th scope="row">{user?.account_number}</th>
        <td>{`${user?.firstname || ""} ${user?.lastname || ""}`}</td>
        <td>{user?.plan_name}</td>
        <td>{user?.phone}</td>
      </tr>
    )
  })

  return (
    <Dashboard title={"PPP Dasboard"}>
      <Row>
        <Col xs="12" md={12} lg={7}>
          <Row>
            <Col xs="6"  className="my-2" sm>
              <SummaryCard
                title={"Total Users"}
                color={"text-dark"}
                value={totalUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Expired Users"}
                color={"text-primary"}
                value={expiredUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Active Users"}
                color={"text-secondary"}
                value={activeUsers}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Income This Month"}
                color={"text-info"}
                value={formatMoney(incomeThisMonth)}
              />
            </Col>
            <Col xs="6" className="my-2" sm>
              <SummaryCard
                title={"Income Today"}
                color={"text-warning"}
                value={formatMoney(incomeToday)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <IncomePerMonth data={data?.monthOnMonthIncomePerPackage} />
            </Col>
          </Row>
        </Col>
        <Col xs="12" md={12} lg={5}>
          <Row>
            <Col>
              <Card>
                <Card.Header><h6>Expired Users</h6></Card.Header>
                <Card.Body>
                  <Table hover responsive className="mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Account Number</th>
                        <th scope="col">Name</th>
                        <th scope="col">Subscription</th>
                        <th scope="col">Expired In </th>
                        <th scope="col">Phone</th>
                      </tr>
                    </thead>
                    <tbody>{listOfExpiredUsers}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Card>
                <Card.Header><h6>Inactive Users</h6></Card.Header>
                <Card.Body>
                  <Table hover responsive className="mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Account Number</th>
                        <th scope="col">Name</th>
                        <th scope="col">Subscription</th>
                        <th scope="col">Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfInactiveUsers}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dashboard>
  )
}

export default PPPDashboard
