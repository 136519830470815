import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useGetAccessTokenMutation } from "../pages/Auth/authApiSlice"
import { setCredentials } from "../pages/Auth/authSlice"
import IsLoading from "./IsLoading"

const PersistLogin = () => {
  const [isfetching, setIsFetching] = useState(true)
  const [getAccessToken] = useGetAccessTokenMutation()
  const dispatch = useDispatch()
  const { access_token } = useSelector((state) => state.auth)

  useEffect(() => {
    let isMounted = true
    const token = localStorage.getItem("ch-token") || false
    const logUserInAgain = async (token) => {
      try {
        const credentials = await getAccessToken(token).unwrap()
        dispatch(setCredentials({ ...credentials }))
        if (credentials?.refreshToken) {
          localStorage.setItem("ch-token", credentials?.refreshToken)
        }
      } catch (error) {
        // do nothing
        isMounted && setIsFetching(false)
      } finally {
        isMounted && setIsFetching(false)
      }
    }

    !access_token && token ? logUserInAgain(token) : setIsFetching(false)

    return () => (isMounted = false)
  }, [])

  return (
    <>
      {isfetching ? (
        <div className="center-loading">
          <IsLoading />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PersistLogin
