import { colors } from "../data/colors";

export const generateRandomColors = (length) => {
   const newcolors = []
    const currentColorsLength = colors.length;
    for(let k=0; k<length; k++) {
        const color = colors[Math.floor(Math.random()*currentColorsLength )]
        newcolors.push(color)
    }

    return newcolors;
    
}