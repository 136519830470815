import React from "react";

// Dashboard



// UI Elements

import HotspotPackages from "../pages/Configuration/Plans/Hotspot";
import PppPackages from "../pages/Configuration/Plans/PPP";
import Routers from "../pages/Configuration/Routers";
import Hotspots from "../pages/Configuration/Hotspots";
import HotspotTransferReport from "../pages/Reports/Hotspot/TranferReport";
import PPPTransferReport from "../pages/Reports/PPP";
import ResellerInfo from "../pages/Setting/ResellerInfo";
import HotspotActiveUsers from "../pages/UserManagement/Hotspot/ActiveUsers";
import PPPUsers from "../pages/UserManagement/PPP";
import HotspotDashboard from "../pages/Dashboard/Hotspot";
import GeneralDashboard from "../pages/Dashboard/General";
import PPPDashboard from "../pages/Dashboard/PPP";
import Profile from "../pages/UserManagement/PPP/Profile";
import Billing from "../pages/Billing";

const protectedRoutes = [
// real routes
{ path: "reseller/hotspot/packages/list", element: <HotspotPackages /> },
{ path: "reseller/ppp/packages/list", element: <PppPackages /> },
{ path: "reseller/routers/list", element: <Routers /> },
{ path: "reseller/hotspots/list", element: <Hotspots /> },
{ path: "reseller/hotspot/transfer/report", element: <HotspotTransferReport /> },
{ path: "reseller/ppp/transfer/report", element: <PPPTransferReport /> },
{ path: "reseller/info", element: <ResellerInfo /> },
{ path: "reseller/hotspot/customers/list", element: <HotspotActiveUsers /> },
{ path: "reseller/ppp/customers/list", element: <PPPUsers /> },
{ path: "reseller/ppp/customer/profile/:accountNumber", element: <Profile /> },
{ path: "dashboard/hotspot/finance", element: <HotspotDashboard /> },
{ path: "dashboard/ppp/finance", element: <PPPDashboard /> },
{ path: "dashboard/general", element: <GeneralDashboard /> },
{ path: "reseller/billing", element: <Billing /> },
];

export default protectedRoutes;