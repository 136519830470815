import { apiSlice } from "../../../../app/api/apiSlice";

export const transferReportAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTranferReport: builder.mutation({
            query: (reseller)=> ({
                url: 'reseller/transfer/report',
                method: 'POST',
                body: {...reseller}
            })
        }),

    })
});

export const { useGetTranferReportMutation } =  transferReportAPiSlice;