import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetResellerInfoQuery } from "./resellerInfoApiSlice"
import { useSelector } from "react-redux"
import { Col, Row, Card, Form } from "react-bootstrap"
import ResellerForm from "./ResellerForm"
import IsLoading from "../../../components/IsLoading"

const ResellerInfo = () => {
  const { data = [], error, isLoading,refetch } = useGetResellerInfoQuery()
  const { user } = useSelector((state) => state.auth)
  //

  let content 
  if(isLoading) {
    content = (<IsLoading />)
  }else if (error) {
    content = error?.message || 'Error Occured'
  }else {
    content = <ResellerForm data={data} />
  }

  return (
    <>
      <Dashboard title={"Reseller Info"}>
        <Row>
          <Col>
            {content}            
          </Col>
        </Row>
      </Dashboard>
    </>
  )
}

export default ResellerInfo
